const convertMinsToHrsMins = (mins: number): string => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  h = h < 10 ? 0 + h : h;
  m = m < 10 ? 0 + m : m;
  return `${h}h:${m}m`;
};

const isLiveNowContent = (content: any): boolean => {
  if (content?.start && content?.end) {
    const start = new Date(content.start);
    const end = new Date(content.end);
    const now = new Date();

    if (start <= now && now <= end) {
      return true;
    }
  }
  return false;
};

const convertLanguageCodeToName = (code: string): string =>
  code === 'en' ? 'english' : 'spanish';

const getPlayedPercentage = (player: any): number => {
  return Math.floor((player?.currentTime() / player?.duration()) * 100) || 0;
};

const isSeries = (type) => {
  return type === 'series';
};

const isChannel = (type: string) => {
  return type === 'channel';
};

const timestamp = function () {
  return new Date().getTime();
  // return Math.floor(new Date().getTime() / 1000)
};

const capitalize = (text: string) => {
  const lower = text.toLowerCase();
  return text.charAt(0).toUpperCase() + lower.slice(1);
};

const getErrorMessage = async (error: unknown) => {
  if (error instanceof Error) return error.message;
  if (error instanceof Response) return (await error.json()).message;
  return String(error);
};

const arrayJoinKeyName = (data: any, name: string) => {
  return (data || []).map((item) => item[name]).join(', ');
};

const getContentSlug = (slug: string, content_type: string) => {
  return isSeries(content_type) ? `/series/${slug}` : `/movie/${slug}`;
};

const generateCDNUrl = (input: string) => {
  const cdnBaseUrl = 'https://serve.butaca.tv';

  // Check if the file name is valid
  if (!input || typeof input !== 'string') {
    console.error('Invalid file name');
    return '/static/img/empty_detail.png';
  }

  if (input.startsWith('http://') || input.startsWith('https://')) {
    return input;
  }

  return `${cdnBaseUrl}/${input}`;
};

export {
  getPlayedPercentage,
  convertLanguageCodeToName,
  convertMinsToHrsMins,
  isLiveNowContent,
  isSeries,
  isChannel,
  timestamp,
  capitalize,
  getErrorMessage,
  arrayJoinKeyName,
  getContentSlug,
  generateCDNUrl,
};
