import { useRef, useState, useCallback, useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { PlayIcon } from '@/icons/play';
import { VolumeUpIcon, VolumeOffIcon } from '@heroicons/react/outline';
import { BookmarkIcon, CheckIcon } from '@heroicons/react/outline';
import { useTranslation } from 'next-i18next';
import useWhislist from '@/hooks/useWhislist';
import { generateCDNUrl, getContentSlug } from '@/utils/index';

type Props = {
  promotion: any;
  isActive: boolean;
};

const PromotionBanner: React.FC<Props> = ({ promotion, isActive }: Props) => {
  const { locale, push } = useRouter();
  const { t } = useTranslation('common');
  const { isOnWhislist, toggleContent } = useWhislist();

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = !isActive || isMuted;
      if (!isActive) {
        setIsMuted(true);
      }
    }
  }, [isActive]);

  const handlePlayClick = useCallback(() => {
    const { slug, content_type } = promotion.contents;
    let path = getContentSlug(slug, content_type);

    if (promotion.link_type === 'channel') {
      path = `/channels?selected=${(promotion.channels || {})._id}`;
    }

    push(path, undefined, { locale });
  }, [locale, promotion, push]);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="relative cursor-pointer">
      {promotion.promotion_type === 'video' ? (
        <div
          className="hidden md:block relative w-full"
          style={{ height: '720px' }}
        >
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            autoPlay
            loop
            muted={isMuted}
            playsInline
            poster={promotion.image_1920x1080 || promotion.image}
            onClick={handlePlayClick}
          >
            <source src={promotion.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button
            className="absolute z-10 bottom-4 right-4 bg-black bg-opacity-50 text-white p-2 rounded-full"
            onClick={toggleMute}
          >
            {isMuted ? (
              <VolumeOffIcon className="w-6 h-6" />
            ) : (
              <VolumeUpIcon className="w-6 h-6" />
            )}
          </button>
        </div>
      ) : (
        <div
          className="hidden md:block relative w-full object-cover"
          style={{ height: '720px' }}
        >
          <Image
            style={{ objectPosition: '75% 100%' }}
            src={generateCDNUrl(promotion.image_name)}
            layout="fill"
            objectFit="cover"
            priority
            alt={promotion.title}
          />
        </div>
      )}

      {/* Mobile and other small displays */}
      <div className="relative flex items-center justify-center md:hidden h-52 rounded-lg overflow-hidden bg-black m-4">
        {promotion.promotion_type === 'video' ? (
          <div className="relative w-full" style={{ maxHeight: '720px' }}>
            <video
              ref={videoRef}
              className="w-full h-full object-cover"
              autoPlay
              loop
              muted={isMuted}
              playsInline
              poster={promotion.image_1920x1080 || promotion.image}
              onClick={handlePlayClick}
            >
              <source src={promotion.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button
              className="absolute z-10 bottom-10 right-4 bg-black bg-opacity-50 text-white p-2 rounded-full"
              onClick={toggleMute}
            >
              {isMuted ? (
                <VolumeOffIcon className="w-6 h-6" />
              ) : (
                <VolumeUpIcon className="w-6 h-6" />
              )}
            </button>
          </div>
        ) : (
          <>
            <Image
              src={generateCDNUrl(promotion.image_name)}
              layout="fill"
              objectFit="cover"
              priority
              alt={promotion.title}
            />
            <div
              className="bg-black bg-opacity-10 backdrop-blur z-10 rounded-full w-16 h-16 flex items-center justify-center"
              onClick={handlePlayClick}
            >
              <PlayIcon className="w-5" />
            </div>
          </>
        )}
      </div>

      {/* Overlay details for larger screens */}
      <div className="hidden md:block absolute left-0 bottom-0 right-0 w-full text-white bg-gradient-to-t from-[#000000cc] to-transparent">
        <div className="container mx-auto flex flex-col py-8 px-16 xl:px-0">
          <div className="font-semibold text-2xl md:text-3xl">
            {promotion.title}
          </div>
          <div className="flex space-x-2 py-4">
            <div className="tag">{promotion.contents[`genre_${locale}`]}</div>
            <div className="tag">{promotion.contents.production_year}</div>
            <div className="tag">{promotion.contents[`country_${locale}`]}</div>
          </div>
          <div className="flex space-x-3">
            <button
              className="rounded-lg bg-accent px-4 py-2 flex items-center space-x-2 text-sm font-semibold"
              onClick={handlePlayClick}
            >
              <PlayIcon className="w-4" />
              <span>{t('play')}</span>
            </button>
            <button
              className="rounded-lg bg-white bg-opacity-10 px-4 py-2 flex items-center space-x-1 text-sm font-semibold "
              onClick={(event) => toggleContent(event, promotion.contents)}
            >
              {!isOnWhislist(promotion.contents) ? (
                <>
                  <BookmarkIcon className="w-6" />
                  <span>{t('queue')}</span>
                </>
              ) : (
                <>
                  <CheckIcon className="w-6" />
                  <span>{t('queued')}</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionBanner;
