/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext } from 'react';
import { MenuIcon, SearchIcon, UserIcon } from '@heroicons/react/outline';
import Image from 'next/image';
import Link from 'next/link';
import Logo from '../../public/static/svg/logo.svg';
import { AppContext } from '@/contexts/app.context';
import router, { useRouter } from 'next/router';
import NavigationBar from '../NavigationBar/NavigationBar';
import { useQuery } from 'react-query';
import Api from '@/services/Api';
import { Language } from '@types';
import GenresSelect from '../GenresSelect/GenresSelect';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import { useTranslation } from 'next-i18next';
import { useSession, signOut } from 'next-auth/client';
import SearchBox from '../SearchBox/SearchBox';
import { event } from '../../utils/gtag';
import { convertLanguageCodeToName } from 'utils';

type Props = {
  float: boolean;
};

const Header: React.FC<Props> = ({ float }: Props) => {
  const { pathname, locale } = useRouter();
  const { t } = useTranslation();
  const { setIsMenuOpen } = useContext(AppContext);
  const [session] = useSession();
  const { data: genres } = useQuery(
    'header_genres',
    () => Api.get('v2/site/header'),
    {
      staleTime: 60000 * 5,
      keepPreviousData: true,
    },
  );

  const handleEvent = (topMenu: string, subMenu: string) => {
    event({
      event: 'menu_click',
      data: {
        brand: 'Butaca TV',
        endpoint_group: 'CTV',
        subbrand: 'Butaca TV',
        endpoint: 'Butaca TV',
        // @ts-ignore
        user_id: session ? session.user.id : '',
        language: convertLanguageCodeToName(locale),
        top_menu: topMenu,
        sub_menu: subMenu,
      },
    });
  };

  return (
    <div
      className={`w-full bg-transparent bg-gradient-to-b from-[#000000cc] to-transparent z-50s
        ${float ? 'static md:absolute top-0 left-0 right-0 z-50' : ''}
      `}
    >
      <div
        className={`container mx-auto flex items-center justify-between p-2 lg:px-0 lg:py-4 bg-transparent`}
      >
        <Link className="flex items-center w-[130px] md:w-auto" href="/">
          <Image
            src={Logo}
            layout="intrinsic"
            priority
            quality="100"
            alt="Butaca TV"
          />
        </Link>
        <div className="hidden md:flex lg:flex-grow justify-center">
          {pathname === '/search' ? <SearchBox /> : <NavigationBar />}
        </div>
        <div className="hidden space-x-2 lg:flex text-sm h-10">
          <div className="flex items-center w-20">
            {genres && (
              <GenresSelect
                label={t('genres')}
                items={genres.map((genre) => ({
                  id: genre._id,
                  value: locale === Language.ES ? genre.name_es : genre.name_en,
                  slug: genre.slug,
                }))}
              />
            )}
          </div>
          {session ? (
            <div className="flex flex-row items-center  w-60">
              <label className="mr-2">{session?.user?.email}</label>
              <ProfileMenu
                icon={
                  <UserIcon className="w-10 h-10 rounded-full bg-gray-800 p-2 cursor-pointer" />
                }
                items={[
                  {
                    id: 2,
                    slug: '/user/list',
                    value: t('list'),
                    callback: () => handleEvent('profile', 'queue'),
                  },
                  {
                    id: 1,
                    slug: '/account',
                    value: t('profile:myData'),
                    callback: () => handleEvent('profile', 'setting'),
                  },
                  {
                    id: 3,
                    slug: '/password',
                    value: t('changePassword'),
                    callback: () => handleEvent('profile', 'password'),
                  },
                  {
                    id: 4,
                    slug: '/notifications',
                    value: t('notifications'),
                    callback: () => handleEvent('profile', 'notifications'),
                  },
                  {
                    id: 5,
                    slug: '/',
                    value: t('logout'),
                    callback: () => {
                      signOut();
                      handleEvent('profile', 'logout');
                    },
                  },
                ]}
              />
            </div>
          ) : (
            <>
              <button
                className="bg-white bg-opacity-10 w-24 rounded-lg px-1 text-xs"
                onClick={() => router.push('/register')}
              >
                {t('register')}
              </button>
              <button
                className="bg-accent w-auto min-w-[8rem] rounded-lg px-2 text-xs"
                onClick={() => router.push('/login')}
              >
                {t('login')}
              </button>
            </>
          )}
        </div>
        <div className="flex space-x-5 pl-4 pr-1 lg:hidden">
          {pathname !== '/search' && (
            <Link href="/search">
              <SearchIcon className="w-8 h-6" />
            </Link>
          )}
          <MenuIcon className="w-8 h-6" onClick={() => setIsMenuOpen(true)} />
        </div>
      </div>
      <div className="md:hidden container mx-auto flex items-center justify-center">
        <NavigationBar />
      </div>
    </div>
  );
};

export default Header;
