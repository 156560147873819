/* eslint-disable @typescript-eslint/ban-ts-comment */
import Image from 'next/image';
import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { BookmarkIcon, CheckIcon } from '@heroicons/react/outline';
import useWhislist from '@/hooks/useWhislist';
import {
  convertLanguageCodeToName,
  convertMinsToHrsMins,
  generateCDNUrl,
} from '../../utils';
import { isMobile } from 'react-device-detect';
import { event } from 'utils/gtag';
import { arrayJoinKeyName } from 'utils';
import { useSession } from 'next-auth/client';

type Props = {
  content: any;
  playlist?: any;
  loadPriority?: boolean;
};

const ContentCard: React.FC<Props> = ({
  content,
  loadPriority,
  playlist,
}: Props) => {
  const { locale } = useRouter();
  const [session] = useSession();
  const { t } = useTranslation();

  const { isOnWhislist, toggleContent } = useWhislist();

  let link = `/movie/${content.slug}`;
  if (content.content_type === 'series') {
    link = `/series/${content.slug}`;
  }
  const handleClick = (item) => {
    event({
      event: 'select_film',
      data: {
        brand: 'Butaca TV',
        endpoint_group: 'Desktop',
        subbrand: 'Butaca TV',
        endpoint: 'Butaca TV',
        // @ts-ignore
        user_id: session ? session.user.id : '',
        language: convertLanguageCodeToName(locale),
        film_id: item._id,
        film_name: item.film_title,
        film_brand: 'Butaca TV',
        film_category: 'film',
        film_genre: item.genre_en,
        film_director: item.director,
        film_country: arrayJoinKeyName(item.countries, 'name_en'),
        film_duration: item.runtime,
        film_url: link,
        playlist_name: playlist?.name_en || '',
        playlist_id: playlist?._id || '',
      },
    });
  };

  return (
    <Link href={link} locale={locale} onClick={handleClick}>
      <div className="w-[160px] md:w-[200px] cursor-pointer">
        <div className="relative overflow-hidden transition-transform duration-300 ease-in-out lg:transform lg:hover:scale-110 lg:hover:shadow-sm">
          <Image
            className="rounded-lg"
            src={generateCDNUrl(content.poster_name || content.poster?.url)}
            width={isMobile ? 160 : 220}
            height={isMobile ? 213 : 300}
            layout="intrinsic"
            alt={content.film_title}
            priority={loadPriority}
          />
          <div className="transition-opacity duration-300 flex flex-col justify-end absolute top-0 right-0 left-0 bottom-0 opacity-100 xl:opacity-0 xl:hover:opacity-100">
            <div className="flex justify-end bg-gradient-to-t from-black to-transparent py-3 px-4">
              <button
                className="transition-opacity duration-300 flex rounded-xl items-center space-x-2 font-semibold lg:text-xs opacity-50 hover:opacity-100"
                onClick={(event) => toggleContent(event, content)}
              >
                {!isOnWhislist(content) ? (
                  <>
                    <span className="hidden lg:block">{t('queue')}</span>
                    <BookmarkIcon className="w-6" />
                  </>
                ) : (
                  <>
                    <span className="hidden lg:block">{t('queued')}</span>
                    <CheckIcon className="w-6" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="hidden flex-col mt-4 space-y-1 w-full lg:flex">
          <div className="font-semibold text-sm">{content.film_title}</div>
          <div className="flex font-thin items-center divide-x-2 divide-white divide-opacity-25">
            {(content.countries || []).length > 0 && (
              <div className="pr-2 text-[10px]">
                {arrayJoinKeyName(content.countries, `name_${locale}`)}
              </div>
            )}
            <div className="px-2 text-xs">{content.production_year}</div>
            {content.runtime > 0 && (
              <div className="pl-2 text-xs">
                {content.runtime && convertMinsToHrsMins(content.runtime)}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ContentCard;
